.work {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.work .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.work .title h2 {
  color: white;
  font-weight: bold;
  line-height: 1.5;
  margin: 35px 0 0;
}

.work .title .underline {
  width: 70%;
  height: 5px;
  border-radius: 5px;
  background-color: #203b40;
  position: relative;
  z-index: -1;
}

.work .title .underline .animation {
  width: 10px;
  height: 5px;
  background-color: #72e2ae;
  position: absolute;
  top: 0;
  animation: left-right 2s infinite;
  border-radius: 5px;
}

.work .work-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.work .work-cards .work-card {
  width: 100%;
  min-height: 250px;
  padding: 10px;
  border-radius: 10px;
  border: 0.1px solid #8a8b8d;
  box-sizing: border-box;
}

.work .work-cards .work-card .project-image {
  width: 100%;
  height: 200px;
  position: relative;
  overflow: hidden;
}

.work .work-cards .work-card .project-image img {
  width: 100%;
  height: 100%;
}

.work .work-cards .work-card .project-image .live-source-icons {
  position: absolute;
  top: 0;
  left: -100%;
  background-color: rgba(0, 0, 0, 0.658);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: all 0.7s;
}

.work .work-cards .work-card .project-image:hover .live-source-icons {
  left: 0;
}

.work .work-cards .work-card .project-image .live-source-icons a {
  border-radius: 50%;
  padding: 10px;
  text-decoration: none;
  background-color: #72e2ae;
  color: black;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.work .work-cards .work-card h3 {
  color: #f7f7f8;
}

.work .work-cards .work-card p {
  color: #9799a7;
}

@keyframes left-right {
  0% {
    left: 0;
  }

  100% {
    left: 95%;
  }
}

@media only screen and (min-width: 768px) {
  .work .title h2 {
    font-size: 35px;
  }

  .work .work-cards {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .work .work-cards .work-card {
    width: 30%;
  }
}

@media only screen and (min-width: 992px) {
  .work .work-cards .work-card .project-image {
    min-height: 300px;
  }
}
