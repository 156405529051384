.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.skills .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.skills .title h2 {
  color: white;
  font-weight: bold;
  line-height: 1.5;
  margin: 35px 0 0;
}

.skills .title .underline {
  width: 70%;
  height: 5px;
  border-radius: 5px;
  background-color: #203b40;
  position: relative;
  z-index: -1;
}

.skills .title .underline .animation {
  width: 10px;
  height: 5px;
  background-color: #72e2ae;
  position: absolute;
  top: 0;
  animation: left-right 2s infinite;
  border-radius: 5px;
}

@keyframes left-right {
  0% {
    left: 0;
  }

  100% {
    left: 95%;
  }
}

.skills .description {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 70px;
  margin-top: 80px;
}

.skills .description .languages,
.skills .description .frameworks,
.skills .description .tools {
  border: 1px solid rgba(255, 255, 255, 0.507);
  border-radius: 5px;
  background-color: #162033;
  width: 90%;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  transition: margin-top 0.5s linear;
}

.skills .description .languages:hover,
.skills .description .frameworks:hover,
.skills .description .tools:hover {
  margin-top: -20px;
}

.skills .description .languages .title,
.skills .description .frameworks .title,
.skills .description .tools .title {
  position: absolute;
  top: -35px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.skills .description .languages .title .image-container,
.skills .description .frameworks .title .image-container,
.skills .description .tools .title .image-container {
  border: 1px solid rgba(255, 255, 255, 0.507);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  z-index: 2;
  background-color: #162033;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills .description .languages .icons li img,
.skills .description .frameworks .icons li img,
.skills .description .tools .icons li img {
  width: 80px;
}

.skills .description .languages .title .image-container img,
.skills .description .frameworks .title .image-container img,
.skills .description .tools .title .image-container img {
  width: 60%;
  height: 60%;
}

.skills .description .languages .title h3,
.skills .description .frameworks .title h3,
.skills .description .tools .title h3 {
  margin: 0;
  color: white;
}

.skills .description .languages .icons,
.skills .description .frameworks .icons,
.skills .description .tools .icons {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  list-style: none;
  margin-top: 100px;
  flex-wrap: wrap;
}

.skills .description .languages .icons li,
.skills .description .frameworks .icons li,
.skills .description .tools .icons li {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.skills .description .languages .icons li span,
.skills .description .frameworks .icons li span,
.skills .description .tools .icons li span {
  color: white;
}

@media only screen and (min-width: 768px) {
  .skills .title h2 {
    font-size: 35px;
  }

  .skills .description {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5%;
  }

  .skills .description .languages,
  .skills .description .frameworks,
  .skills .description .tools {
    width: 45%;
    min-height: 600px;
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) {
  .skills .description {
    gap: 2%;
  }

  .skills .description .languages,
  .skills .description .frameworks,
  .skills .description .tools {
    width: 30%;
    margin-bottom: 0;
  }
}
