.recommendation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.recommendation .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.recommendation .title h2 {
  color: white;
  font-weight: bold;
  line-height: 1.5;
  margin: 35px 0 0;
}

.recommendation .title .underline {
  width: 70%;
  height: 5px;
  border-radius: 5px;
  background-color: #203b40;
  position: relative;
  z-index: -1;
}

.recommendation .title .underline .animation {
  width: 10px;
  height: 5px;
  background-color: #72e2ae;
  position: absolute;
  top: 0;
  animation: left-right 2s infinite;
  border-radius: 5px;
}

@keyframes left-right {
  0% {
    left: 0;
  }

  100% {
    left: 95%;
  }
}

.recommendation .recommendation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
}

.recommendation .recommendation-container .recommendation-cards {
  display: flex;
  align-items: center;
  gap: 5%;
  overflow-x: auto;
  overflow-y: hidden;
  transition: all 0.2s;
  width: 90%;
  padding: 70px 2% 0;
}

.recommendation .recommendation-container .recommendation-cards.smooth-scroll {
  scroll-behavior: smooth;
}

.recommendation .recommendation-container .recommendation-cards::-webkit-scrollbar {
  width: 0;
}

.recommendation .recommendation-container button {
  display: block;
  border: none;
  background: transparent;
  color: #8a8b8d;
  transition: all 0.2s;
  cursor: pointer;
}

.recommendation .recommendation-container button:hover {
  color: #72e2ae;
}

.recommendation .recommendation-container button div {
  border: 2px solid #8a8b8d;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
}

.recommendation .recommendation-container button:hover div {
  border: 2px solid #72e2ae;
}

.recommendation .recommendation-container .recommendation-cards .recommendation-card {
  border: 1px solid #8a8b8d;
  border-radius: 10px;
  min-width: 100%;
  padding: 55px 5% 10px;
  box-sizing: border-box;
  background-color: #162033;
  position: relative;
}

.recommendation .recommendation-container .recommendation-cards .recommendation-card img {
  position: absolute;
  top: -60px;
  left: calc(50% - 56px);
  border-radius: 50%;
  border: 3px solid #72e2ae;
  display: block;
}

.recommendation .recommendation-container .recommendation-cards .recommendation-card h3 {
  color: #e4e5e7;
}

.recommendation .recommendation-container .recommendation-cards .recommendation-card h4 {
  color: #9ca2b3;
}

.recommendation .recommendation-container .recommendation-cards .recommendation-card p {
  color: #616676;
  font-weight: bold;
}

@media only screen and (min-width: 768px) {
  .recommendation .title h2 {
    font-size: 35px;
  }

  .recommendation .recommendation-container {
    padding: 0 3%;
    box-sizing: border-box;
    flex-wrap: nowrap;
    gap: 2%;
  }

  .recommendation .recommendation-container button:first-of-type {
    order: -1;
  }

  .recommendation .recommendation-container .recommendation-cards {
    gap: 2%;
    padding: 70px 0 0;
  }

  .recommendation .recommendation-container .recommendation-cards .recommendation-card {
    min-width: 49%;
    padding: 55px 2% 10px;
  }

  .recommendation .recommendation-container .recommendation-cards .recommendation-card img {
    left: 30px;
  }
}

@media only screen and (min-width: 1200px) {
  .recommendation .recommendation-container {
    padding: 0 5%;
  }

  .recommendation .recommendation-container .recommendation-cards {
    gap: 1%;
  }

  .recommendation .recommendation-container .recommendation-cards .recommendation-card {
    min-width: 32.5%;
  }
}
