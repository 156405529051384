.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.contact .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.contact .title h2 {
  color: white;
  font-weight: bold;
  line-height: 1.5;
  margin: 35px 0 0;
}

.contact .title .underline {
  width: 70%;
  height: 5px;
  border-radius: 5px;
  background-color: #203b40;
  position: relative;
  z-index: -1;
}

.contact .title .underline .animation {
  width: 10px;
  height: 5px;
  background-color: #72e2ae;
  position: absolute;
  top: 0;
  animation: left-right 2s infinite;
  border-radius: 5px;
}

@keyframes left-right {
  0% {
    left: 0;
  }

  100% {
    left: 95%;
  }
}

@media only screen and (min-width: 768px) {
  .contact .title h2 {
    font-size: 35px;
  }
}

.contact .contact-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #8a8b8d;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  background-color: #162033;
  gap: 20px;
  margin-top: 20px;
}

.contact .contact-form label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  width: 100%;
}

.contact .contact-form label input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #8a8b8d;
  background-color: #161f32;
  color: #bfbecb;
}

.contact .contact-form label input:focus {
  border-color: #72e2ae;
}

.contact .contact-form label textarea {
  height: 100px;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  outline: none;
  background-color: #161f32;
  color: #bfbecb;
}

.contact .contact-form label textarea:focus {
  border-color: #72e2ae;
}

.contact .contact-form button {
  background-color: #72e2ae;
  padding: 15px 20px;
  border: 0;
  border-radius: 5px;
  font-weight: 500;
}

.contact .contact-form .success-msg {
  background-color: #172835;

  --tw-text-opacity: 1;

  color: rgb(34 197 94/var(--tw-text-opacity));
  font-weight: 500;
  text-align: center;
  width: 100%;
  padding: 5px;
  display: none;
}

.contact .line {
  width: 90%;
  border-top: 0.5px solid #bfbecb;
  margin: 50px 0 0;
}

.contact .icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}

.contact .icons .twitter,
.contact .icons .linkedin,
.contact .icons .github {
  border: 0.5px solid rgba(255, 255, 255, 0.1);
  text-decoration: none;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  width: 40px;
  height: 40px;
}

.contact .icons .twitter .twitter-not-hovered,
.contact .icons .linkedin .linkedin-not-hovered,
.contact .icons .github .github-not-hovered {
  top: 0;
  color: white;
}

.contact .icons .twitter .twitter-hovered,
.contact .icons .linkedin .linkedin-hovered,
.contact .icons .github .github-hovered {
  top: 40px;
  background-color: #72e2ae;
  color: #0b1224;
}

.contact .icons .twitter .twitter-not-hovered,
.contact .icons .twitter .twitter-hovered,
.contact .icons .linkedin .linkedin-not-hovered,
.contact .icons .linkedin .linkedin-hovered,
.contact .icons .github .github-not-hovered,
.contact .icons .github .github-hovered {
  position: absolute;
  left: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  border-radius: 5px;
}

.contact .icons .twitter:hover .twitter-not-hovered,
.contact .icons .linkedin:hover .linkedin-not-hovered,
.contact .icons .github:hover .github-not-hovered {
  top: -40px;
}

.contact .icons .twitter:hover .twitter-hovered,
.contact .icons .linkedin:hover .linkedin-hovered,
.contact .icons .github:hover .github-hovered {
  top: 0;
}

@media only screen and (min-width: 768px) {
  .contact .contact-form {
    width: 70%;
  }
}

@media only screen and (min-width: 1200px) {
  .contact .contact-form {
    width: 50%;
  }
}
