html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Radio Canada', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0b1224;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

@media only screen and (min-width: 992px) {
  body::-webkit-scrollbar {
    width: 8px;
    background-color: #383838;
  }

  body::-webkit-scrollbar-thumb {
    background: #6b6b6b;
    border-radius: 10px;
  }
}
